import { Input } from '@/react/components/ui/input';
import carbon from '@/utils/carbon';
import { cn } from '@/utils/cn';
import { Link, useForm } from '@inertiajs/react';
import type { FormEventHandler } from 'react';

export default function SecurityIndex({
  email,
  redirectTo
}: {
  email: string;
  redirectTo: string;
}) {
  const { data, setData, errors, ...form } = useForm({
    email: email,
    password: '',
    remember_me: false
  });

  const date = carbon();
  const xmass =
    (date.month() === 0 && date.date() <= 6) || (date.month() === 11 && date.date() >= 6);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    form.post(route('login', redirectTo ? { redirect_to: redirectTo } : {}), {
      preserveScroll: true,
      preserveState: true,
      onError: () => {
        setData('password', '');
      }
    });
  };

  return (
    <div className="tw-max-w-sm tw-mx-auto tw-w-full tw-flex tw-items-center tw-flex-col tw-space-y-2">
      <div className="tw-py-2 tw-border-solid tw-border tw-w-full tw-border-gray-200 tw-rounded tw-flex tw-flex-col tw-items-center tw-px-4">
        <a href="https://www.hermix.com">
          <img
            src={xmass ? '/images/hermix_logo_christmas.png' : '/images/hermix_logo.png'}
            className="tw-w-[175px] tw-mb-2 tw-mt-2"
          />
        </a>
        <div className="tw-w-full">
          <form onSubmit={handleSubmit}>
            <div className="tw-hidden" hidden>
              <input name="_honeypot" />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="tw-block">
                Email
              </label>
              <Input
                id="email"
                name="email"
                value={data.email}
                onChange={(e) => setData('email', e.target.value)}
                className={cn({ 'is-invalid': errors.email })}
                autoComplete="email"
                required
              />
              <span className="invalid-feedback">{errors.email}</span>
            </div>
            <div className="form-group tw-flex tw-flex-col-reverse">
              <div>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  value={data.password}
                  onChange={(e) => setData('password', e.target.value)}
                  required
                  autoComplete="password"
                />
              </div>
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <label htmlFor="password" className="tw-block tw-mb-0">
                  Password
                </label>
                <Link
                  className="underline text-sm text-gray-600 hover:text-gray-900 tw-text-sm"
                  preserveState={true}
                  href={route(
                    'password.request',
                    data.email && {
                      email: data.email
                    }
                  )}
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
            <div className="form-group" hidden>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  checked
                  readOnly
                  className="custom-control-input"
                  name="remember_me"
                  id="remember_me"
                />
                <label className="custom-control-label" htmlFor="remember_me">
                  Remember me
                </label>
              </div>
            </div>
            <div className="form-group">
              <button disabled={form.processing} type="submit" className="btn btn-dark btn-block">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="tw-py-2 tw-w-full tw-rounded tw-flex tw-flex-col tw-items-center tw-px-4">
        <span className="tw-text-sm">
          <p className="tw-my-2">
            Don't have an account?
            <a
              className="underline btn btn-primary text-sm tw-ml-2 text-gray-600 hover:text-gray-900"
              href="https://www.hermix.com/sign-up/"
            >
              Sign up
            </a>
          </p>
        </span>
      </div>
    </div>
  );
}
